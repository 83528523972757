
module.exports = {
    myHost: {
        // 'host':' http://103.174.50.34:8044',
        'host':'https://api-v2.smicee.com',
        // 'host':'https://api-digital.smicee.com',
        // 'host':'https://api-pixeltag.smicee.com',
        // 'host':'https://api-catchbd.smicee.com',
        // 'host':'https://migrate.smicee.com',
    }
};
